<template>
  <div class="work_container">
    <div class="work_page">
        <div class="echarts_container track_style">
            <div class="echarts_title_container flex_between ">
              <div class="echarts_title">统调运行数据</div>

                <div class="flex_start">
                <el-select v-model="dateTrack"  placeholder="请选择" @change="changeTrack" >
                <el-option
                v-for="item in dateType.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
            </div>
            </div>
           
          <div ref="track" id="track" ></div>
        </div>
            <div class="echarts_container track_style">
            <div class="echarts_title_container flex_between ">
              <div class="echarts_title">温度走线图</div>

                <div class="flex_start">
                <el-select v-model="dateTemperature"  placeholder="请选择" @change="changeTemperature" >
                <el-option
                v-for="item in dateType.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
            </div>
            </div>
           
          <div ref="temperature" id="temperature" ></div>
        </div>
    </div>

    <div class="work_page">
             <div class="echarts_container timely_style">
            <div class="echarts_title_container flex_between ">
              <div class="echarts_title">实时温度数据</div>


            </div>
           
          <div ref="timely" id="timely" ></div>
        </div>


             <div class="echarts_container timely_style">
            <div class="echarts_title_container flex_between ">
              <div class="echarts_title">快捷入口</div>

        

            
   
            </div>
                  <div class="entrance flex_between">
                <div class="entrance_item" v-for="(item,index) in entrance.options" :key="index">
                 <div class="top_mode"></div>
                 <div>{{item.label}}</div>
                </div>
          
              </div>
           
        
        </div>
    </div>
  </div>
</template>

<script>
import {reactive,ref,getCurrentInstance,nextTick,onMounted} from 'vue'
export default {
 name: 'workbench',
  setup(){
     const { proxy } = getCurrentInstance() // proxy是组件实例化对象
       const entrance=reactive({
        options:[
            {label:'数据导入',value:1},
            {label:'因素系数',value:2},
            {label:'价格走势',value:3},
            {label:'导入历史',value:4},
            {label:'导入模板',value:5},
            {label:'天气数据',value:6},
        ]
     })

     const dateType=reactive({
        options:[
            {label:'日',value:1},
            {label:'月',value:2},
            {label:'年',value:3},
        ]
     })
     let dateTrack=ref(1)
     let dateTemperature=ref(1)
    // 统调运行数据
    let track = reactive({options: {
    grid: {
    top: '20%',
    left:'5%',
    right:'8%',
    bottom: '30'
  },
   legend: {
    data: ['预测数据', '实际数据'],
    textStyle:{
    color:'#FFFFFF'
    }
    },  
    xAxis: {
    type: 'category',
    data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00','09:00','10:00'],
    boundaryGap:false,
     axisLine: { onZero: false },
      axisLabel: {         //轴上的字 字体、样式
            textStyle: {
                color: '#FFFFFF',
            }
      }
  },
  yAxis: {
      
    type:'value',
    name:'单位：MW',
    nameTextStyle:{
    color:'#FFFFFF'
    },
      axisLine: { onZero: false },
            axisLabel: {         //轴上的字 字体、样式
            textStyle: {
                color: '#FFFFFF',
            },
      },
    splitLine:{
    show:true,
    lineStyle:{
        type:'dashed'
    }
      }
  },
  series: [
    {
      name:'预测数据',
      data: [1, 15, 52, 100, 23, 55, 75,23, 55,23,5],
      type: 'line',
      smooth: true
    },
      {
      name:'实际数据',
      data: [55, 75,23, 55,23,5,0,67,23,100,5],
      type: 'line',
      smooth: true
    }
   ]
  }
  })
      const trackChart = async () => {
            // 渲染图表
            const chart = proxy.$echarts.init(document.getElementById('track'))
            chart.setOption(track.options)
            window.onresize = () => {
                chart.resize()
            }
             
     }
   // 温度走线数据
let temperature= reactive({options: {
    grid: {
    top: '20%',
    left:'5%',
    right:'8%',
    bottom: '30'
  },
  
    xAxis: {
    type: 'category',
    data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00','09:00','10:00'],
    boundaryGap:false,
     axisLine: { onZero: false },
      axisLabel: {         //轴上的字 字体、样式
            textStyle: {
                color: '#FFFFFF',
            }
      }
  },
  yAxis: {
      
    type:'value',
    name:'单位：摄氏度',
    nameTextStyle:{
    color:'#FFFFFF'
    },
      axisLine: { onZero: false },
            axisLabel: {         //轴上的字 字体、样式
            textStyle: {
                color: '#FFFFFF',
            },
      },
    splitLine:{
    show:true,
    lineStyle:{
        type:'dashed'
    }
      }
  },
  series: [
    {
      name:'预测数据',
            emphasis: {
        focus: 'series'
      },
     areaStyle: {
         opacity: 0.4,
        color: proxy.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#17E6FF'
          },
          {
            offset: 1,
            color: '#1995FF'
          }
        ])
     },
      data: [1, 15, 52, 100, 23, 55, 75,23, 55,23,5],
      type: 'line',
      smooth: true
    },

   ]
  }
  })
      const temperatureChart= async ()=>{
           // 渲染图表
            const chart = proxy.$echarts.init(document.getElementById('temperature'))
            chart.setOption(temperature.options)
            window.onresize = () => {
                chart.resize()
            }
   }

let timely=reactive({options: {
    grid: {
    top: '20%',
    left:'10%',
    right:'8%',
    bottom: '30'
  },
  
    xAxis: {
    type: 'category',
    data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00','09:00','10:00'],
    boundaryGap:false,
     axisLine: { onZero: false },
      axisLabel: {         //轴上的字 字体、样式
            textStyle: {
                color: '#FFFFFF',
            }
      },
   
  },
  yAxis: {
      
    type:'value',
    name:'单位：摄氏度',
    nameTextStyle:{
    color:'#FFFFFF'
    },
      axisLine: { onZero: false },
            axisLabel: {         //轴上的字 字体、样式
            textStyle: {
                color: '#FFFFFF',
            },
      },
      
    splitLine:{
    show:false,
    lineStyle:{
        type:'dashed'
    }
      }
  },
  series: [
    { 
      name:'预测数据',
            emphasis: {
        focus: 'series'
      },
     areaStyle: {
         opacity: 0.4,
        color: proxy.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#17E6FF'
          },
          {
            offset: 1,
            color: '#1995FF'
          }
        ])
     },
      data: [1, 15, 52, 100, 23, 55, 75,23, 55,23,5],
      type: 'line',
      smooth: false
    },

   ]
  }
  })
          const timelyChart= async ()=>{
           // 渲染图表
            const chart = proxy.$echarts.init(document.getElementById('timely'))
            chart.setOption(timely.options)
            window.onresize = () => {
                chart.resize()
            }
   }
   const changeTrack=()=>{
      if(dateTrack.value==1){
         track.options.xAxis.data=['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00','09:00','10:00']
         track.options.series=[
     {
      name:'预测数据',
      data: [1, 15, 52, 100, 23, 55, 75,23, 55,23,5],
      type: 'line',
      smooth: true
    },
      {
      name:'实际数据',
      data: [55, 75,23, 55,23,5,0,67,23,100,5],
      type: 'line',
      smooth: true
    }
   ]
     nextTick(() => { 
             trackChart()
     })
      }else if(dateTrack.value==2){
         track.options.xAxis.data=['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月','十月','十一月']
         track.options.series=[
     {
      name:'预测数据',
      data: [ 100,1, 15, 75,23,52, 23, 55,55,23,5],
      type: 'line',
      smooth: true
    },
      {
      name:'实际数据',
      data: [ 5,75,23,100,23,5,0,55,67,23, 55],
      type: 'line',
      smooth: true
    }
   ]
     nextTick(() => { 
             trackChart()
     })
      }else if(dateTrack.value==3){
         track.options.xAxis.data=['2012年', '2013年', '2014年', '2015年', '2016年', '2017年', '2018年', '2019年', '2020年','2021年','2022年']
         track.options.series=[
     {
      name:'预测数据',
      data: [ 15,15,15,15,15,75,15,15,15,15,5],
      type: 'line',
      smooth: true
    },
      {
      name:'实际数据',
      data: [ 15,15,25,15,15,54,15,15,75,15,65],
      type: 'line',
      smooth: true
    }
   ]
     nextTick(() => { 
             trackChart()
     })
      }
   }
   
   const changeTemperature=()=>{
    console.log()
      if(dateTemperature.value==1){
         temperature.options.xAxis.data=['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00','09:00','10:00']
         temperature.options.series=[{
      name:'预测数据',
            emphasis: {
        focus: 'series'
      },
     areaStyle: {
         opacity: 0.4,
        color: proxy.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#17E6FF'
          },
          {
            offset: 1,
            color: '#1995FF'
          }
        ])
     },
      data: [1, 15, 52, 100, 23, 55, 75,23, 55,23,5],
      type: 'line',
      smooth: true
    },]
     nextTick(() => { 
             temperatureChart()
     })
      }else if(dateTemperature.value==2){
         temperature.options.xAxis.data=['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月','十月','十一月']
         temperature.options.series=[
  {
      name:'预测数据',
            emphasis: {
        focus: 'series'
      },
     areaStyle: {
         opacity: 0.4,
        color: proxy.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#17E6FF'
          },
          {
            offset: 1,
            color: '#1995FF'
          }
        ])
     },
      data: [1, 15, 52, 100, 23, 55, 75,23, 55,23,5],
      type: 'line',
      smooth: true
    },
   ]
     nextTick(() => { 
             temperatureChart()
     })
      }else if(dateTemperature.value==3){
         temperature.options.xAxis.data=['2012年', '2013年', '2014年', '2015年', '2016年', '2017年', '2018年', '2019年', '2020年','2021年','2022年']
         temperature.options.series=[
  {
      name:'预测数据',
            emphasis: {
        focus: 'series'
      },
     areaStyle: {
         opacity: 0.4,
        color: proxy.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#17E6FF'
          },
          {
            offset: 1,
            color: '#1995FF'
          }
        ])
     },
      data: [1, 15, 52, 100, 23, 55, 75,23, 55,23,5],
      type: 'line',
      smooth: true
    },
   ]
     nextTick(() => { 
             temperatureChart()
     })
      }
   }
   
      
   onMounted(()=>{
     nextTick(() => { 
             trackChart()
             temperatureChart()
             timelyChart()
     })
        })
   return{
    track,
    trackChart,
    dateType,
    dateTrack,
    changeTrack,
    temperatureChart,
    temperature,
    timely,
    timelyChart,
    entrance,
    changeTemperature,
    dateTemperature
   }

  },

}
</script>

<style lang="scss" scoped>
.work_container{
  display: flex;
  justify-content: space-between;
}
 .work_page{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  
    .track_style{
        width: 1160px;
    }
   #track{
    width: 100%;
    height: 409px;


   }
   #temperature{
        width: 100%;
    height: 409px;

   }
   .timely_style{

        width: 450px;
   }
   #timely{
    width: 100%;
    height: 310px;
   }
   .echarts_container{
      display: flex;
      flex-wrap: wrap;   
      justify-content: flex-start;
      box-shadow: inset 0px 0px 10px 0px #3767AD;
          background: #0B183C;
            margin-bottom: 30px;
    border-radius: 8px;
    padding: 20px 0;
    .echarts_title_container{
    width: 100%;

     .echarts_title{
       
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-indent: 2em;
       
      &::before {
        display:block;

    content: '';
    width: 4px;
    height: 25px;
    background: linear-gradient(135deg, #0081FF 0%, #22CCE2 100%);
    border-radius: 0px 2px 2px 0px;


  }

    }
     &:deep( .el-input__wrapper){
          background-color: #0B183C !important;
        border: 1px solid #8291A9;
        width: 130px;
        margin-right: 17px;
    }

    
    }

   }
   .entrance{
    margin-top: 20px;
    border-top: 1px solid #8291A9;;
    padding: 35px 25px 25px;
    width: 100%;
    height: 509px;
    box-sizing: border-box;


  //  height: 518px;
   flex-wrap: wrap;
    .entrance_item{
      width: 70px;
      margin: 25px 25px ;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      .top_mode{
        width: 70px;
        height: 70px;
        background: linear-gradient(180deg, #3090E4 0%, #09D3D5 100%);
        border-radius: 8px;
        margin-bottom: 24px;

      }
      
    }
    
   }
    }
</style>
